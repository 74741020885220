import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';

/**
 * The video.js doesn't contain definitely typed library for the videojs-record.
 * And there is no such library at npm at all.
 * At the runtime it is working correct, so we're suppressing typescript compiler here intentionally.
 */
/* @ts-ignore */
import * as MediaRecord from 'videojs-record';
import { generateFileName } from '@zc/common/core/utils/name-generator';
import { NotificationService } from '@zc/common/core/services/notification.service';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { FileFormat } from '@zc/common/core/enums/file-format';

/** Type of media that should be recorded. */
export type MediaRecordType = 'video' | 'image';

const DEFAULT_PLAYER_WIDTH = 880;
const MAX_PLAYER_WIDTH = 1280;

const DEFAULT_PLAYER_HEIGHT = 650;
const MAX_PLAYER_HEIGHT = 720;

const IMAGE_FILE_TYPE = 'image/jpeg';

const MAX_LENGTH_OF_VIDEO_SEC = 120;

export const EMPTY_RECORD_DATA_MESSAGE = 'The file was not saved. Please, try again';

/**
 * The interface itself is purely typed, so we need to extend it here.
 */
interface PlayerWithRecordedData extends VideoJsPlayer {

  /**
   * Data that was recorded.
   */
  readonly recordedData?: Blob;
}

/**
 * Component for recording media files - videos, images.
 */
@Component({
  selector: 'zc-media-recorder',
  templateUrl: './media-recorder.component.html',
  styleUrls: ['./media-recorder.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaRecorderComponent implements AfterViewInit, OnDestroy {

  /** Video element. */
  @ViewChild('video')
  public videoElement!: ElementRef;

  /** Type of record. */
  @Input()
  public recordType: MediaRecordType = 'image';

  /** Emit file on upload. */
  @Output()
  public readonly mediaUpload = new EventEmitter<File>();

  /** Plugin for video recording. */
  private readonly plugin = MediaRecord;

  /** Player options. */
  private readonly options: Readonly<Record<MediaRecordType, VideoJsPlayerOptions>>;

  /** Player instance. */
  private player: PlayerWithRecordedData | null = null;

  public constructor(
    private readonly notificationService: NotificationService,
  ) {
    this.options = this.initializeOptions();
  }

  /** @inheritDoc */
  public ngAfterViewInit(): void {
    this.player = videojs(this.videoElement.nativeElement, this.options[this.recordType]);

    this.player.on('finishRecord', () => {
      assertNonNull(this.player);
      if (this.player.recordedData == null) {
        this.notificationService.notify(EMPTY_RECORD_DATA_MESSAGE);
      } else {

        /** The library using the .mkv format for video files. */
        const fileExtension = this.recordType === 'image' ? FileFormat.JPEG : FileFormat.MKV;
        this.mediaUpload.emit(new File([this.player.recordedData], generateFileName(fileExtension), {
          type: this.player.recordedData.type,
        }));
      }
    });

    this.player.on('error', (_, error: string) => {
      this.notificationService.notify(error);
    });
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    if (this.player != null) {
      this.player.dispose();
    }
  }

  private initializeOptions(): Readonly<Record<MediaRecordType, VideoJsPlayerOptions>> {
    return {
      image: {
        controls: true,
        fluid: false,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false,
          fullscreenToggle: false,
        },
        width: DEFAULT_PLAYER_WIDTH,
        height: DEFAULT_PLAYER_HEIGHT,
        plugins: {
          record: {
            imageOutputType: 'blob',
            imageOutputFormat: IMAGE_FILE_TYPE,
            imageOutputQuality: 0.9,
            image: {
              width: {
                min: DEFAULT_PLAYER_WIDTH,
                ideal: DEFAULT_PLAYER_WIDTH,
                max: MAX_PLAYER_WIDTH,
              },
              height: {
                min: DEFAULT_PLAYER_HEIGHT,
                ideal: DEFAULT_PLAYER_HEIGHT,
                max: MAX_PLAYER_HEIGHT,
              },
            },
          },
        },
      },
      video: {
        controls: true,
        width: DEFAULT_PLAYER_WIDTH,
        height: DEFAULT_PLAYER_HEIGHT,
        fluid: false,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false,
          fullscreenToggle: false,
        },
        plugins: {
          record: {
            audio: true,
            video: true,
            maxLength: MAX_LENGTH_OF_VIDEO_SEC,
            displayMilliseconds: false,
          },
        },
      },
    };
  }
}
