<header class="header">
  <a [href]="marketingSiteUrl" class="header__logo">
    <span class="visually-hidden">Main Page</span>
    <zw-main-logo></zw-main-logo>
  </a>
  <nav class="header__nav" [class.header__menu_active]="isMenuOpened$ | async">
    <ul class="header__links-list">
      <li><a class="header__link" [href]="companySiteUrl">Company</a></li>
      <li><a class="header__link" [href]="featuresSiteUrl">Features</a></li>
      <li><a class="header__link" [href]="pricingSiteUrl">Pricing</a></li>
      <li class="header__logout">
        <a
          class="header__link"
          *ngIf="(isAuthorized$ | async) !== true; else logoutButton"
          routerLink="auth/login"
        >
          Login
        </a>
        <ng-template #logoutButton>
          <button class="header__link" (click)="onLogoutClick()">Logout</button>
        </ng-template>
      </li>
    </ul>
  </nav>
  <zc-burger-menu
    class="header__menu"
    [isActive]="(isMenuOpened$ | async) ?? false"
    (toggle)="isMenuOpened$.next(!isMenuOpened$.value)"
  >

  </zc-burger-menu>
</header>
<main class="main">
  <router-outlet></router-outlet>
</main>
