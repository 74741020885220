import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/** Burger menu component. */
@Component({
  selector: 'zc-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BurgerMenuComponent {

  /** Whether menu is active or not. */
  @Input()
  public isActive = false;

  /** Toggle menu event. */
  @Output()
  public readonly toggle = new EventEmitter<void>();
}
