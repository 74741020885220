import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const NON_APPLICABLE_SYMBOL = '-';

/** Numeric pipe. */
@Pipe({
  name: 'zcNumber',
})
export class NumberPipe extends DecimalPipe implements PipeTransform {

  /** @inheritdoc */
  public override transform(value: number | string | null): string | null;

  /** @inheritdoc */
  public override transform(value: null | undefined): null;

  /** @inheritdoc */
  public override transform(value: number | string | null | undefined): string | null {
    if (value == null) {
      return NON_APPLICABLE_SYMBOL;
    }

    return super.transform(value);
  }
}
