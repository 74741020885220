<ng-container *ngIf="safeSource">
  <ng-container *ngIf="isVideo && withPreview; else image">
    <video [draggable]="false" class="document" alt="Video">
      <source [attr.src]="safeSourceUrl">
      <img [src]="getImagePath(FileFormat.MP4)" />
    </video>
  </ng-container>

  <ng-template #image>
    <div class="document-wrapper" [ngSwitch]="fileFormat">
      <img *ngSwitchCase="FileFormat.DOC" [src]="getImagePath(FileFormat.DOC)" alt="Docs document format">
      <img *ngSwitchCase="FileFormat.PDF" [src]="getImagePath(FileFormat.PDF)" alt="Pdf document format">
      <img class="document" *ngSwitchCase="FileFormat.JPEG" [src]="getImagePath(FileFormat.JPEG)" alt="Jpeg document format">
      <img class="document" *ngSwitchCase="FileFormat.JPG" [src]="getImagePath(FileFormat.JPG)" alt="Jpg document format">
      <img class="document" *ngSwitchCase="FileFormat.PNG" [src]="getImagePath(FileFormat.PNG)" alt="Png document format">

      <!-- Add asset for unknown / undefined document type when it will be ready. -->
      <img *ngSwitchDefault [src]="getImagePath(FileFormat.Unknown)" alt="Unknown document format">
    </div>
  </ng-template>

</ng-container>
