<div
  class="star"
  *ngFor="let i of [1, 2, 3, 4, 5]"
  [attr.aria-label]="'Rating is ' + rating?.value"
  (click)="selectStar(i)"
>
  <ng-container [ngSwitch]="getStarType(i)" >
    <ng-container
      *ngSwitchCase="starTypes.Empty"
      [ngTemplateOutlet]="empty"
    ></ng-container>
    <ng-container
      *ngSwitchCase="starTypes.Full"
      [ngTemplateOutlet]="full"
    ></ng-container>
    <ng-container
      *ngSwitchCase="starTypes.Half"
      [ngTemplateOutlet]="half"
    ></ng-container>
  </ng-container>
</div>

<ng-template #empty>
  <svg
    viewBox="0 0 15 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      opacity="0.5"
    >
      <g
        transform="translate(-714.000000, -443.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <path
          d="M717.740618,456.899132 L721.314443,455.020615 L724.888268,456.899132 C725.522647,457.23273 726.280046,456.696804 726.156999,455.977644 L725.473407,451.999138 L728.36364,449.182738 C728.88317,448.67688 728.596061,447.793685 727.879651,447.689784 L723.884749,447.107369 L722.099212,443.487054 C721.779286,442.841754 720.852324,442.833556 720.529674,443.487054 L718.744137,447.107369 L714.749235,447.689784 C714.032825,447.793685 713.745716,448.67688 714.265246,449.182738 L717.155479,451.999138 L716.471887,455.977644 C716.348841,456.696804 717.106239,457.235454 717.740618,456.899132 Z M717.335937,456.125311 L718.096086,451.695634 L714.875005,448.553834 L719.323828,447.908533 L721.314443,443.87534 L723.305058,447.908533 L727.753881,448.553834 L724.535524,451.69291 L725.295673,456.122587 L721.314443,454.033519 L717.335937,456.125311 Z"
          id="icons/rating-empty"
        ></path>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #half>
  <svg
    viewBox="0 0 15 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-676.000000, -443.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <path
          d="M687.313307,456.999433 C687.821889,456.999433 688.276344,456.545244 688.179012,455.976768 L687.494592,451.998529 L690.390566,449.18269 C690.911191,448.676832 690.623522,447.79361 689.90575,447.689709 L685.903452,447.107027 L684.114897,443.486979 C683.954119,443.163528 683.640495,443 683.32687,443 C683.015141,443 682.703706,443.161338 682.542368,443.486979 L680.753279,447.10676 L676.750714,447.688908 C676.032942,447.792809 675.745273,448.675737 676.26563,449.181595 L679.161044,451.997995 L678.475823,455.97594 C678.378491,456.54415 678.832118,456.999967 679.340166,456.999967 C679.474962,456.999967 679.613603,456.967977 679.746743,456.897428 L683.327404,455.019472 L686.907798,456.897962 C687.040671,456.96771 687.179045,456.999433 687.313307,456.999433 Z M686.732254,455.322709 L683.939646,453.857365 L683.327698,453.536344 L683.328499,444.863189 L684.723855,447.687519 L685.029548,448.306304 L685.713434,448.405852 L688.836889,448.860574 L686.576923,451.058456 L686.081479,451.540255 L686.198491,452.220563 L686.732254,455.322709 Z"
          id="icons/rating-half"
        ></path>
      </g>
    </g>
  </svg>
</ng-template>

<ng-template #full>
  <svg
    viewBox="0 0 15 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-637.000000, -443.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <path
          d="M640.740618,456.899132 L644.314443,455.020615 L647.888268,456.899132 C648.522647,457.23273 649.280046,456.696804 649.156999,455.977644 L648.473407,451.999138 L651.36364,449.182738 C651.88317,448.67688 651.596061,447.793685 650.879651,447.689784 L646.884749,447.107369 L645.099212,443.487054 C644.779286,442.841754 643.852324,442.833556 643.529674,443.487054 L641.744137,447.107369 L637.749235,447.689784 C637.032825,447.793685 636.745716,448.67688 637.265246,449.182738 L640.155479,451.999138 L639.471887,455.977644 C639.348841,456.696804 640.106239,457.235454 640.740618,456.899132 Z"
          id="icons/rating-full"
        ></path>
      </g>
    </g>
  </svg>
</ng-template>
