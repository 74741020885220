import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonSharedModule } from '@zc/common/shared/common-shared.module';
import '@saritasa/angular-typed-forms';

import { AuthInterceptor } from '@zc/common/core/interceptors/auth-interceptor';
import { WINDOW } from '@zc/common/core/injection-tokens/window-token';

import { RefreshTokenInterceptor } from '@zc/common/core/interceptors/refresh-token-interceptor';

import { NgxMaskModule } from 'ngx-mask';

import { DialogsModule } from '@zc/common/shared/modules/dialogs/dialogs.module';

import { DEFAULT_DIALOG_HOST_PROVIDER } from '@zc/common/shared/modules/dialogs/dialogs.service';

import { FileUploadService } from '@zc/common/core/services/file-upload';

import { S3UploadService } from '@zc/common/core/services/s3-upload.service';

import { ErrorNotifier } from '@zc/common/core/services/error-notifier';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { LandingModule } from './features/landing/landing.module';

const httpInterceptorProviders = [
  // The refresh interceptor should be before the auth interceptor, otherwise refreshed bearer would not be updated
  { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

/** Base app module. */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonSharedModule,
    LayoutsModule,

    // We turn off validation of Ngx mask to handle it manually.
    NgxMaskModule.forRoot({ validation: false }),
    LandingModule,
    DialogsModule,
  ],
  providers: [
    ...httpInterceptorProviders,
    {
      provide: WINDOW,
      useValue: window,
    },
    DEFAULT_DIALOG_HOST_PROVIDER,
    {
      provide: FileUploadService,
      useClass: S3UploadService,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorNotifier,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
