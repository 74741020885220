<aside class="aside">
  <nav class="nav">
    <ul class="nav__list list">
      <li *ngFor="let item of items; trackBy: trackItem" class="list__item">
        <ng-container *ngIf="isLink(item)">
          <a
            class="list__link"
            routerLinkActive="list__link_active"
            [routerLink]="item.link"
          >
            <img class="list__icon" [src]="item.icon ?? fallbackPath" alt="">
            <span class="list__text">{{ item.label }}</span>
          </a>
        </ng-container>
        <ng-container *ngIf="isButton(item)">
          <button
            class="list__link"
            (click)="item.onClick()"
          >
            <img class="list__icon" [src]="item.icon ?? fallbackPath" alt="">
            <span class="list__text">{{ item.label }}</span>
          </button>
        </ng-container>
      </li>
    </ul>
  </nav>
</aside>
