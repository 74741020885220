import { ChangeDetectorRef, Directive, ViewChild } from '@angular/core';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { getImageSource } from '@zc/common/core/utils/image-src';

import { FileValidation } from '@zc/common/core/utils/file-validation';

import { DialogsService } from '../../modules/dialogs/dialogs.service';
import { AlertDialogComponent } from '../../modules/dialogs/alert-dialog/alert-dialog.component';

import { ImageUploaderComponent, ImageValidation } from './image-uploader.component';

/** Base implementation of the image uploader. */
@Directive()
export class BaseImageUploaderImplementation extends SimpleValueAccessor<File | string> {

  /** Image uploader element. */
  @ViewChild('uploader', { static: true })
  public readonly uploader?: ImageUploaderComponent;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    private readonly domSanitizer: DomSanitizer,
    private readonly dialogService: DialogsService,
  ) {
    super(changeDetectorRef);
  }

  /**
   * Handles file selection.
   * @param event Click event.
   */
  public onSelectFile(event: MouseEvent): void {
    assertNonNull(this.uploader);
    event.preventDefault();
    this.uploader.selectFile();
  }

  /**
   * Handles file removal.
   * @param event Event.
   */
  public onRemoveFile(event: MouseEvent): void {
    assertNonNull(this.uploader);
    event.preventDefault();
    this.uploader.removeFile();
  }

  /**
   * Handles file change.
   * @param event Event.
   */
  public onFileChange(event: Event): void {
    event.preventDefault();
    const { files } = event.target as HTMLInputElement;
    const file = files ? files[0] : null;

    if (file == null) {
      this.controlValue = null;
      return;
    }

    const isFileFormatValid = FileValidation.validateFormat(ImageValidation.ALLOWED_FILE_EXTENSIONS)(file) == null;

    if (isFileFormatValid) {
      this.controlValue = file;
    } else {
      this.dialogService.openDialog(AlertDialogComponent, {
        buttonTitle: 'OK',
        message: 'We couldn\'t upload the file, format is not acceptable',
        title: 'Invalid file format',
      });
    }
  }

  /**
   * Returns image url.
   * @param file File to obtain the url for.
   * @param imageElement Image element.
   * @returns Safe image url.
   */
  public getFileUrl(file: File | string | null, imageElement: HTMLImageElement): string | SafeUrl {
    if (typeof file === 'string') {
      return file;
    }

    if (file == null) {
      return '';
    }

    const imageSource = getImageSource(file);
    imageElement.onload = imageSource.teardown;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(imageSource.unsanitizedUri);
  }
}
