import { Pipe, PipeTransform } from '@angular/core';
import { ProjectCancelReason } from '@zc/common/core/models/project-cancel-reason';

/** Cancel reason formatting pipe. */
@Pipe({
  name: 'zcCancelReason',
})
export class CancelReasonPipe implements PipeTransform {

  /** @inheritdoc */
  public transform(value: string | null, reasonList: readonly ProjectCancelReason[]): string {
    if (value === null) {
      return '';
    }
    const item = reasonList.find(reason => reason.type === value);
    if (item) {
      return item.name;
    }
    return value;
  }
}
