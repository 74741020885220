<mat-icon
  class="tooltip-icon"
  [ngxTippy]="htmlTemplate"
  [tippyProps]="config"
>
  help_outline
</mat-icon>

<template #htmlTemplate>
  <ng-content></ng-content>
</template>
