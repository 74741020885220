import { NgModule } from '@angular/core';
import { RouterModule, Route, Router, ChildActivationEnd } from '@angular/router';
import { UnauthorizedGuard } from '@zc/common/core/guards/unauthorized.guard';
import { ColorTheme } from '@zc/common/shared/theme/color-theme';
import { filter, map } from 'rxjs/operators';

import { AppThemeService } from '../../../common/shared/theme/app-theme.service';

import { AppRoutingResolver } from './app-routing.resolver';
import { SellerGuard } from './features/sellers-redirect/seller.guard';

interface SubApplicationData {

  /** Application theme for sub application. */
  readonly theme: ColorTheme;
}

type SubApplicationRoute = Route & {
  readonly data?: SubApplicationData;
};

const subApplicationRoutes: SubApplicationRoute[] = [
  {
    path: '',
    loadChildren: () => import('./features/landing/landing.module').then(m => m.LandingModule),
    resolve: {
      data: AppRoutingResolver,
    },
    data: {
      theme: ColorTheme.ReMax,
    },
  },
  {
    path: 'z-box',
    loadChildren: () => import('./features/z-box/z-box.module').then(m => m.ZBoxModule),
    canLoad: [UnauthorizedGuard, SellerGuard],
    data: {
      theme: ColorTheme.Grey,
    },
  },
  {
    path: 'member-portal',
    loadChildren: () => import('./features/member-portal/member-portal.module').then(m => m.MemberPortalModule),
    canLoad: [UnauthorizedGuard, SellerGuard],
    data: {
      theme: ColorTheme.ReMax,
    },
  },
  {
    path: 'seller',
    loadChildren: () => import('./features/sellers-redirect/sellers-redirect.module').then(m => m.SellersRedirectModule),
  },

  // https://web.dev/change-password-url/
  { path: '.well-known/change-password', redirectTo: 'auth/forgot-password' },
];

/** App routing module. */
@NgModule({
  imports: [RouterModule.forRoot(subApplicationRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {

  public constructor(
    router: Router,
    appThemeService: AppThemeService,
  ) {
    router.events.pipe(
      filter((event): event is ChildActivationEnd => event instanceof ChildActivationEnd),
      map(event => event.snapshot),
      filter(snapshot => snapshot.pathFromRoot.length === 2),
      map(snapshot => snapshot.data as SubApplicationData | undefined),
      map(data => data?.theme),
    ).subscribe(theme => theme ? appThemeService.setTheme(theme) : appThemeService.setDefaultTheme());
  }
}
