import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { NotificationService } from './notification.service';

const DEFAULT_MESSAGE = 'You don\'t have permission to perform this action!';

/** Presents an unhandled error message to a user. */
@Injectable({
  providedIn: 'root',
})
export class ErrorNotifier implements ErrorHandler {

  public constructor(
    private readonly notificationService: NotificationService,
  ) { }

  /** @inheritdoc */
  public handleError(error: unknown): void {
    if (error instanceof HttpErrorResponse && error.status === 403) {
      const message = error.error?.detail ? error.error.detail : DEFAULT_MESSAGE;
      this.notificationService.notify(message);
    }
  }
}
