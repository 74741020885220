import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@zc/common/core/models/address';

/** Inline address component. */
@Component({
  selector: 'zc-dashboard-address',
  templateUrl: './dashboard-address.component.html',
  styleUrls: ['./dashboard-address.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardAddressComponent {
  /** Address object. */
  @Input()
  public address: Address | null = null;

  /** Get full address. */
  public get fullAddress(): string | undefined {
    return this.address ?
      [this.address.addressLine1, Address.getUnit(this.address)]
        .filter(addressPart => addressPart && addressPart !== '')
        .join(', ') :
      void 0;
  }

  /** Get city, state and zipcode. */
  public get cityStateZipCode(): string | undefined {
    return this.address ? Address.getCityStateZipCode(this.address) : void 0;
  }
}
