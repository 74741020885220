import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from '@zc/common/core/guards/authorized.guard';

import { LandingWithoutHeaderComponent } from './auth/landing-without-header/landing-without-header.component';
import { LandingComponent } from './landing.component';
import { LandingGuard } from './landing.guard';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    canActivateChild: [LandingGuard],
    canActivate: [LandingGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'auth',
      },
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [AuthorizedGuard],
      },
    ],
  },
  {
    path: '',
    component: LandingWithoutHeaderComponent,
    canActivateChild: [LandingGuard],
    canActivate: [LandingGuard],
    children: [
      {
        path: 'auth/new-agent',
        loadChildren: () => import('./auth/agent-registration/agent-registration.module').then(m => m.AgentRegistrationModule),
        canActivate: [AuthorizedGuard],
      },
    ],
  },
];

/** Landing app module. */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingRoutingModule { }
