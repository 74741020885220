import { Pipe, PipeTransform } from '@angular/core';

/** Join pipe for ZinCasa. */
@Pipe({
  name: 'zcJoin',
})
export class JoinPipe implements PipeTransform {

  /** @inheritdoc */
  public transform(value: string[], type = ','): string {
    return value.filter(item => item && item !== '').join(type);
  }

}
