<div class="dropzone" [class.dropzone_disabled]="disabled">
  <ng-container *ngIf="uploadedFiles$ | async as files; else placeholder">
    <div
      class="document"
      *ngFor="let file of files; trackBy: trackFile"
  >
    <zc-document-image
      class="document__image"
      [file]="file"
    >
    </zc-document-image>
    <span class="document__name" zcEllipsis>{{ file.name }}</span>
  </div>
  </ng-container>
  <input
    [zcDisabled]="disabled"
    class="dropzone__input"
    type="file"
    (change)="onFileUpload($event)"
    multiple
  />
</div>

<ng-template #placeholder>
  <img
    class="dropzone__image"
    src="/assets/document-formats/add-document.svg"
    alt=""
  />
  <span class="dropzone__text"
    >Drag and place document here or click to browse for documents</span
  >
</ng-template>
