<ng-container *ngIf="controlValue">
  <select
    [ngModel]="controlValue"
    (ngModelChange)="onValueChanged($event)"
    [compareWith]="optionComparator"
    class="date-range-select"
  >
    <option
      [ngValue]="{ value: { start: undefined, end: undefined } }"
      disabled
    >
      Select option
    </option>
    <option [ngValue]="option" *ngFor="let option of options">
      {{ option.label }}
    </option>
  </select>
</ng-container>
