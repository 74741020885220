import { Pipe, PipeTransform } from '@angular/core';
import { OperationTerritory } from '@zc/common/core/models/operation-territory';

/** Transforms array of teritories into a human-readable value. */
@Pipe({
  name: 'zcTerritories',
})
export class TerritoriesPipe implements PipeTransform {
  /** @inheritdoc */
  public transform(value: readonly OperationTerritory[]): string {
    return value.map(t => t.name).join(', ');
  }
}
