import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Main logo. */
@Component({
  selector: 'zw-main-logo',
  templateUrl: './main-logo.component.html',
  styleUrls: ['./main-logo.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLogoComponent {

}
