import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

/**
 * Checkbox component.
 */
@Component({
  selector: 'zc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(CheckboxComponent)],
})
export class CheckboxComponent extends SimpleValueAccessor<boolean> {

  /** Checkbox label. */
  @Input()
  public label = '';

  /** Whether checkbox has label. */
  @Input()
  public withLabel = true;
}
