import { Pipe, PipeTransform } from '@angular/core';

import { getQuarter } from 'date-fns';

/**
 * Quarter pipe.
 */
@Pipe({
  name: 'zcQuarter',
})
export class QuarterPipe implements PipeTransform {

  /** @inheritDoc */
  public transform(date: Date): string {
    return `Q${getQuarter(date)} ${date.getFullYear()}`;
  }
}
