<div class="avatar-uploader">
  <zc-image-uploader
    #uploader
    [(ngModel)]="controlValue"
    class="avatar-uploader__tile"
  >
    <zc-avatar
      class="avatar-uploader__image"
      [src]="(uploader.fileChange | async) ?? controlValue"
    ></zc-avatar>
    <div class="avatar-uploader__controls">
      <button
        class="avatar-uploader__control control"
        [class.avatar-uploader__control_hidden]="
          (uploader.fileChange | async) ?? controlValue
        "
        title="Add new avatar"
        type="button"
        (click)="onSelectFile($event)"
      >
        <mat-icon class="control__icon material-icons-outlined">
          photo_camera
        </mat-icon>
      </button>
      <button
        type="button"
        class="avatar-uploader__control avatar-uploader__control_hidden control"
        *ngIf="controlValue !== null"
        title="Delete the photo"
        (click)="onRemoveFile($event)"
      >
        <mat-icon class="control__icon">delete</mat-icon>
      </button>
    </div>
  </zc-image-uploader>

  <!-- Safely disable tabindex since image-uploader is already focusable -->
  <button
    type="button"
    tabindex="-1"
    [disabled]="disabled"
    title="Remove the avatar"
    class="avatar-uploader__button"
    (click)="onSelectFile($event)"
  >
    <mat-icon>add_circle_outline</mat-icon>
    <span class="avatar-uploader__button-title">
      {{ buttonTitle }}
    </span>
  </button>
  <p *ngIf="withValidationTip" class="avatar-uploader__tip">
    {{ avatarValidationTip }}
  </p>
</div>
