<button
  type="button"
  class="quarter-control zc-input"
  [disabled]="disabled"
  (click)="menu.toggle()"
>
  {{ controlValue ? (controlValue | zcQuarter) : "Select Quarter" }}
</button>
<zc-overlay-menu #menu>
  <zc-overlay-menu-content>
    <div class="picker">
      <div class="picker__header">
        <button class="picker__navigate" (click)="onYearDecrement()">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <div>
          {{ year }}
        </div>
        <button class="picker__navigate" (click)="onYearIncrement()">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
      <ul class="picker__quarters">
        <li *ngFor="let q of quarters">
          <button
            class="picker__quarter-control"
            (click)="onQuarterPick(q); menu.close()"
            [disabled]="isDisabled(q)"
            [class.picker__quarter-control_active]="isActive(q)"
            [class.picker__quarter-control_current]="isCurrent(q)"
          >
            {{ quarterToReadable(q) }}
          </button>
        </li>
      </ul>
    </div>
  </zc-overlay-menu-content>
</zc-overlay-menu>
