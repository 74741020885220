import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { WINDOW } from '@zc/common/core/injection-tokens/window-token';
import { convertBase64ToBlob } from '@zc/common/core/utils/image-src';
import { QRCodeComponent as QRCodeComponentLibrary, QRCodeElementType } from 'angularx-qrcode';

/** QR Code component. */
@Component({
  selector: 'zc-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QRCodeComponent {

  /** QR data source. */
  @Input()
  public qrData = '';

  /** Type of QR image. */
  @Input()
  public elementType: QRCodeElementType = 'canvas';

  /** Width by pixel.*/
  @Input()
  public width = 256;

  public constructor(
    @Inject(WINDOW) private readonly window: Window,
  ) { }

  /**
   * Handle download QR code image.
   * @param parent Input QR Code element.
   */
  public downloadQR(parent: QRCodeComponentLibrary): void {
    let parentElement = null;

    if (this.elementType === 'canvas') {
      parentElement = parent.qrcElement.nativeElement.querySelector('canvas').toDataURL('image/png');
    } else if (this.elementType === 'img' || this.elementType === 'url') {
      parentElement = parent.qrcElement.nativeElement.querySelector('img').src;
    } else {
      return;
    }

    if (parentElement) {
      // converts base 64 encoded image to blobData
      const blobData = convertBase64ToBlob(parentElement);

      // saves as image
      const blob = new Blob([blobData], { type: 'image/png' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'Qrcode';
      link.click();
    }
  }

}
