import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Money } from '@zc/common/core/services/mappers/money';

/** Transforms money into a human-readable value. */
@Pipe({
  name: 'zcMoney',
})
export class MoneyPipe implements PipeTransform {

  public constructor(
    private readonly currencyPipe: CurrencyPipe,
  ) {}

  /** @inheritdoc */
  public transform(value: Money | undefined | null): ReturnType<CurrencyPipe['transform']> {
    if (value == null) {
      return '-';
    }

    return this.currencyPipe.transform(value.unitsAmount, value.currency.toUpperCase(), 'symbol', '1.0');
  }
}
