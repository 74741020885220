import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Aside nav link item. */
export interface AsideNavLink<T> {

  /** Id of the link. */
  readonly id: T;

  /** Router link. */
  readonly link: string;

  /** Human-readable label. */
  readonly label: string;

  /** Icon name. */
  readonly icon?: string;
}

/** Aside nav button item. */
export interface AsideNavButton<T> {

  /** Id of the button. */
  readonly id: T;

  /** Human-readable label. */
  readonly label: string;

  /** Icon name. */
  readonly icon?: string;

  /** Click handler. */
  readonly onClick: () => void;
}

export type AsideNavItem<T> = AsideNavLink<T> | AsideNavButton<T>;

/** Aside nav list. Renders a provided list of items within a side bar. */
@Component({
  selector: 'zc-aside-nav',
  templateUrl: './aside-nav.component.html',
  styleUrls: ['./aside-nav.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsideNavComponent<T> {

  /** Elements to render in a nav list. */
  @Input()
  public items: readonly AsideNavItem<T>[] | null = null;

  /** Path to fallback icon. */
  public readonly fallbackPath = 'assets/search-icon.svg';

  /**
   * Type guard for aside nav link.
   * @param item Item to check.
   */
  public isLink(item: AsideNavItem<T>): item is AsideNavLink<T> {
    return (<AsideNavLink<T>>item).link != null;
  }

  /**
   * Type guard for aside nav button.
   * @param item Item to check.
   */
  public isButton(item: AsideNavItem<T>): item is AsideNavButton<T> {
    return (<AsideNavButton<T>>item).onClick != null;
  }

  /**
   * Trackby function.
   * @param _ Idx.
   * @param item Item to track.
   */
  public trackItem(_: number, item: AsideNavItem<T>): string {
    return item.label;
  }
}
