<ng-container *ngIf="options">
  <label
    *ngFor="let option of options"
    class="radio"
    [class.disabled]="disabled"
  >
    <input
      [disabled]="disabled"
      type="radio"
      class="radio__input visually-hidden"
      [name]="formControlName || name || ''"
      [checked]="comparator(option, controlValue)"
      (change)="onCheckChange(option)"
    />
    <div class="radio__target"></div>
    {{ toReadable(option) }}
  </label>
</ng-container>
