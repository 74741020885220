import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Quarter } from '@zc/common/core/models/quarter';
import { enumToArray } from '@zc/common/core/utils/enum-to-array';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';
import { setQuarter, getYear, isThisQuarter, isSameQuarter } from 'date-fns';

/** Quarter picker control. */
@Component({
  selector: 'zc-quarter-picker',
  templateUrl: 'quarter-picker.component.html',
  styleUrls: ['quarter-picker.component.css'],
  providers: [controlProviderFor(QuarterPickerComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuarterPickerComponent extends SimpleValueAccessor<Date> {
  /**
   * Disables a date for selection when falsy.
   * @param _date Date to filter.
   */
  @Input()
  public dateFilter = (_date: Date | null): boolean => true;

  /** Current year in a selector. */
  public year = new Date().getFullYear();

  /** Quarter options. */
  public readonly quarters = enumToArray(Quarter);

  /** Maps quarter to human-readable value. */
  public readonly quarterToReadable = Quarter.toReadable;

  /** @inheritdoc */
  public override writeValue(value: Date | null): void {
    super.writeValue(value);

    if (value) {
      this.year = getYear(value);
    }
  }

  /**
   * Handles quarter pick.
   * @param q Picked quarter.
   */
  public onQuarterPick(q: Quarter): void {
    this.controlValue = this.quarterToDate(q, this.year);
  }

  /** Increments a year. */
  public onYearIncrement(): void {
    this.year++;
  }

  /** Decrements a year. */
  public onYearDecrement(): void {
    this.year--;
  }

  /**
   * Checks whether the quarter should be disabled for selection.
   * @param q Quarter.
   */
  public isDisabled(q: Quarter): boolean {
    return !this.dateFilter(this.quarterToDate(q, this.year));
  }

  /**
   * Checks whether the quarter is the current one.
   * @param q Quarter.
   */
  public isCurrent(q: Quarter): boolean {
    return isThisQuarter(this.quarterToDate(q, this.year));
  }

  /**
   * Checks is quarter is selected.
   * @param q Quarter.
   */
  public isActive(q: Quarter): boolean {
    if (this.controlValue == null) {
      return false;
    }

    return isSameQuarter(
      this.controlValue,
      this.quarterToDate(q, this.year),
    );
  }

  private quarterToDate(q: Quarter, year: number): Date {
    return setQuarter(new Date(year, 0, 1), Quarter.toNumber(q));
  }
}
