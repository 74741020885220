import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DateFilterFn } from '@matheo/datepicker';
import { MatCalendarType } from '@matheo/datepicker/lib/calendar.types';
import { enumToArray } from '@zc/common/core/utils/enum-to-array';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

/**
 * Available types of datepicker.
 */
export enum DatepickerType {
  Date = 'date',
  Datetime = 'datetime',
  Time = 'time',
  Month = 'month',
  Year = 'year',
  Quarter = 'quarter',
}

/**
 * Type guard for the types supportable by material API.
 * @param type Type to check.
 */
export function isMatDatepickerSupportedType(type: string): type is MatCalendarType {
  return enumToArray(DatepickerType)
    .filter(t => t !== DatepickerType.Quarter)
    .map(String)
    .includes(type);
}

/**
 * Common datepicker component.
 */
@Component({
  selector: 'zc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(DatepickerComponent)],
})
export class DatepickerComponent extends SimpleValueAccessor<Date> {

  private readonly internalDateFilter = (date: Date | null): boolean => {
    if (date == null) {
      return false;
    }

    if (this.untilNow) {
      return date.valueOf() <= Date.now();
    }

    return true;
  };

  /**
   * Filter that combines both internal and external date filters.
   * @param date Date to filter.
   */
  public readonly _dateFilter = (date: Date | null): boolean => this.internalDateFilter(date) && this.dateFilter(date);

  /** Filter for dates. All are available by default. */
  @Input()
  public dateFilter: DateFilterFn<Date | null> = () => true;

  /** Type of datepicker. */
  @Input()
  public type: DatepickerType = DatepickerType.Date;

  /** Control placeholder. */
  @Input()
  public placeholder = '';

  /** Whether input should be displayed. */
  @Input()
  public withInput = true;

  /** Whether the dates should be filtered until the current date. */
  @Input()
  public untilNow = false;

  /** Emits new date on input event. */
  @Output()
  public readonly pick = new EventEmitter<Date>();

  /** Type-guar for datepicker-supported types. */
  public readonly isMatDatepickerSupportedType = isMatDatepickerSupportedType;

  /** Datepicker types. */
  public readonly DatepickerType = DatepickerType;

  /** Handle date change. */
  public onDateChange(): void {
    if (this.controlValue) {
      this.pick.emit(this.controlValue);
    }
  }
}
