import { Component, ChangeDetectionStrategy } from '@angular/core';
import { NgxTippyProps } from 'ngx-tippy-wrapper';

/** Main logo. */
@Component({
  selector: 'zc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolTipComponent {
  /** Tippy props config. */
  public config: NgxTippyProps = {
    arrow: false,
    allowHTML: true,
    appendTo: 'parent',
    interactive: true,
    maxWidth: '300px',
  };
}
