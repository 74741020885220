import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonSharedModule } from '@zc/common/shared/common-shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MainLogoComponent } from './components/main-logo/main-logo.component';
import { FilterPickerComponent } from './components/filter-picker/filter-picker.component';
import { ProjectsTableComponent } from './components/projects-table/projects-table.component';
import { GetsStartedCardComponent } from './components/gets-started-card/gets-started-card.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';

/** Web shared module. */
@NgModule({
  declarations: [
    MainLogoComponent,
    FilterPickerComponent,
    ProjectsTableComponent,
    GetsStartedCardComponent,
    ChangePasswordFormComponent,
  ],
  exports: [
    MainLogoComponent,
    FilterPickerComponent,
    ProjectsTableComponent,
    GetsStartedCardComponent,
    ChangePasswordFormComponent,
  ],
  imports: [
    CommonModule,
    CommonSharedModule,
    RouterModule,
    ReactiveFormsModule,
  ],
})
export class WebSharedModule {}
