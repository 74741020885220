<div
  *ngIf="task"
  [class.task_expired]="task.isExpired"
  [class.task_proceeding]="!task.isExpired"
>
  <div class="task">
    <div class="task-description">
      <span class="task-description__title">{{ task.title }}</span>
      <span class="divider">|</span>
      <span
        class="task-description__due-date"
        [class.task-description__due-date_expired]="task.isExpired"
      >
        {{ datePreposition }}
        {{ task.dueDate | zcTime: "day" }}
      </span>
    </div>
    <div class="task__relations task-relations">
      <span
        class="task-relations__badge badge"
        [ngClass]="getTaskStatusBadgeClasses()"
      >
        {{ toReadableTaskStatus(task.status) }}
      </span>
      <span class="task-relations__stage">{{ task.stage.type.name }}</span>
      <span class="divider"> | </span>
      <span class="task-relations__assignee">
        Assigned to:
        <zc-username
          *ngIf="!task.completed"
          class="task-relations__name"
          [user]="task.assignee"
        ></zc-username>
      </span>
    </div>
  </div>
  <div *ngIf="withNote && task.description" class="task__note">Task Notes: {{task.description}}</div>
</div>
