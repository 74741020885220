<div class="date-picker">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="DatepickerType.Quarter">
      <zc-quarter-picker
        [(ngModel)]="controlValue"
        [dateFilter]="_dateFilter"
        [disabled]="disabled"
      >
      </zc-quarter-picker>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="isMatDatepickerSupportedType(type)">
        <input
          [disabled]="disabled"
          class="date-picker__control"
          [class.visually-hidden]="!withInput"
          matInput
          [matDatepicker]="picker"
          [(ngModel)]="controlValue"
          (dateInput)="onDateChange()"
          [matDatepickerFilter]="_dateFilter"
          [placeholder]="placeholder"
        />
        <mat-datepicker-toggle
          [class.date-picker__toggle_block]="!withInput"
          class="date-picker__toggle"
          matSuffix
          [for]="picker"
        >
          <img
            [class.date-picker__image]="!withInput"
            matDatepickerToggleIcon
            src="/assets/calendar-dark.svg"
            alt="Date"
          />
        </mat-datepicker-toggle>
        <mat-datepicker [type]="type" #picker></mat-datepicker>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
