<div class="logo-uploader">
  <zc-image-uploader
    #uploader
    [(ngModel)]="controlValue"
    class="logo-uploader__control"
  >
    <div class="logo-uploader__layout">
      <ng-container
        *ngIf="
          (uploader.fileChange | async) ?? controlValue as file;
          else noImagePlaceholder
        "
      >
        <div
          (dragover)="handleDrag()"
          (drop)="handlePlace()"
          (dragend)="handlePlace()"
        >
          <img
            #imageUploader
            [src]="getFileUrl(file, imageUploader)"
            alt=""
            class="logo-uploader__image"
          />
          <div
            class="control control_add"
            [class.control_hidden]="uploader.fileChange | async"
            [class.logo-uploader__image_dragover]="isDragging"
          >
            <input
              class="control__input"
              title="Add new logo"
              type="file"
              (change)="onFileChange($event)"
            />
            <mat-icon class="control__icon control__icon_upload material-icons-outlined">
              photo_camera
            </mat-icon>
          </div>
          <button
            [class.control_hidden]=" (uploader.fileChange | async)"
            type="button"
            class="control control_remove"
            *ngIf="controlValue !== null"
            title="Delete the photo"
            (click)="onRemoveFile($event)"
          >
            <mat-icon class="control__icon control__icon_delete">delete</mat-icon>
          </button>
        </div>
      </ng-container>

      <ng-template #noImagePlaceholder>
        <div class="logo-uploader__image-not-uploaded">
          <input
            [zcDisabled]="disabled"
            type="file"
            (change)="onFileChange($event)"
            *ngIf="!disabled"
            class="logo-uploader__input"
          />
          <mat-icon class="logo-uploader__icon material-icons-outlined">
            photo
          </mat-icon>
          <span class="logo-uploader__upload-text">Upload image </span>
        </div>
      </ng-template>
    </div>
  </zc-image-uploader>

  <p class="logo-uploader__tip">
    {{ logoValidationTip }}
  </p>
</div>
