import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonSharedModule } from '@zc/common/shared/common-shared.module';
import { RouterModule } from '@angular/router';

import { AsideLayoutComponent } from './components/aside-layout/aside-layout.component';

/**
 * Module containing different layout wrappers to visually distinguish the set of features.
 */
@NgModule({
  declarations: [AsideLayoutComponent],
  exports: [AsideLayoutComponent],
  imports: [CommonModule, CommonSharedModule, RouterModule],
})
export class LayoutsModule { }
