import { MapsAPILoader } from '@agm/core';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild, Input } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

/** Google Autocomplete input component. */
@Component({
  selector: 'zc-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(AutocompleteInputComponent)],
})
export class AutocompleteInputComponent extends SimpleValueAccessor<string> {
  /** Input Placeholder. */
  @Input()
  public placeholder = 'Enter your address';

  /** Autocomplete field reference. */
  private searchElementRef: ElementRef | undefined;

  /** Setter for Autocomplete when ngIf change. */
  @ViewChild('autocompleteInput')
  private set content(content: ElementRef) {
    if (content && this.searchElementRef === undefined) {
      // initially setter gets called with undefined
      this.searchElementRef = content;
      this.initAutocomplete();
    }
  }

  public constructor(
    private readonly mapsAPILoader: MapsAPILoader,
    private readonly ngZone: NgZone,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  /**
   * Handle init google autocomplete.
   */
  private initAutocomplete(): void {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef?.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.formatted_address) {
            this.onChange(place.formatted_address);
          }
        });
      });
    });
  }

  /**
   * Handles autocomplete change value.
   * @param data Autocomplete data.
   */
  public onChange(data: string): void {
    this.controlValue = data;
  }
}
