import { ChangeDetectionStrategy, Component } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

/**
 * Switch control.
 */
@Component({
  selector: 'zc-switch-component',
  templateUrl: './switch-control.component.html',
  styleUrls: ['./switch-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(SwitchControlComponent)],
})
export class SwitchControlComponent extends SimpleValueAccessor<boolean> {

}
