import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanLoad,
  Router,
  UrlTree,
} from '@angular/router';
import { UserRoleGroup } from '@zc/common/core/models/user-role-group';
import { UserService } from '@zc/common/core/services/user.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

/** Guard prevents Sellers from accessing route. */
@Injectable({
  providedIn: 'root',
})
export class SellerGuard implements CanActivate, CanLoad {
  public constructor(
    private readonly userService: UserService,
    private readonly router: Router,
  ) {}

  /** @inheritdoc */
  public canLoad(): Observable<boolean | UrlTree> {
    return this.canNavigate();
  }

  /** Determine if /auth route can be activated. */
  public canActivate(): Observable<boolean | UrlTree> {
    return this.canNavigate();
  }

  private canNavigate(): Observable<boolean | UrlTree> {
    return this.userService.currentUser$.pipe(
      map(user => user?.roleGroup !== UserRoleGroup.Seller ? true : this.router.parseUrl('/seller')),
      first(),
    );
  }
}
