import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { FiveStarRating } from '@zc/common/core/models/five-star-rating';

/** Rating star type. */
enum StarType {
  Full,
  Half,
  Empty,
}

/** Component for displaying a list of rating stars. */
@Component({
  selector: 'zc-five-star-rating',
  templateUrl: './five-star-rating.component.html',
  styleUrls: ['./five-star-rating.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiveStarRatingComponent {

  /** 5-based rating number. */
  @Input()
  public rating?: FiveStarRating;

  /** Whether rating is editable or not. */
  @Input()
  public isEditable?: boolean;

  /** Emits selected value in the progress when it's clicked. */
  @Output()
  public readonly selected = new EventEmitter<number>();

  /** Star types. */
  public readonly starTypes = StarType;

  /**
   * Returns star type for iterated int index from 1 to 5.
   * @param idx Index.
   */
  public getStarType(idx: number): StarType {

    if (this.rating == null) {
      return StarType.Empty;
    }

    if (this.rating.value >= idx) {
      return StarType.Full;
    }

    if (this.rating.value <= (idx - 0.5)) {
      return StarType.Empty;
    }

    return StarType.Half;
  }

  /**
   * Handle user select star.
   * @param idx Index number.
   */
  public selectStar(idx: number): void {
    if (!this.isEditable) {
      return;
    }
    this.selected.emit(idx ?? 0);
  }
}
