<input
  class="autocomplete-input"
  autocorrect="off"
  autocapitalize="off"
  spellcheck="off"
  type="text"
  #autocompleteInput
  [ngModel]="controlValue"
  (ngModelChange)="onChange($event)"
  [placeholder]="placeholder"
  zcFixedBody
/>
