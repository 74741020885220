import { FileFormat } from '@zc/common/core/enums/file-format';

const NAME_LENGTH = 15;

/**
 * Generate random name for a file.
 * @param fileFormat File format.
 */
export function generateFileName(fileFormat: FileFormat): string {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < NAME_LENGTH; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return `${result}${fileFormat}`;
}
