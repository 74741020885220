import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@zc/common/core/services/app-config.service';
import { UserService } from '@zc/common/core/services/user.service';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { BehaviorSubject, Observable, tap } from 'rxjs';

/** Landing entry component. */
@Destroyable()
@Component({
  selector: 'zw-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent implements OnInit {

  /** Whether current user is authorized. */
  public readonly isAuthorized$: Observable<boolean>;

  /** Homepage marketing site url. */
  public readonly marketingSiteUrl: string;

  /** Company site url. */
  public readonly companySiteUrl: string;

  /** Features site url. */
  public readonly featuresSiteUrl: string;

  /** Pricing site url. */
  public readonly pricingSiteUrl: string;

  /** Whether menu is opened or not. */
  public readonly isMenuOpened$ = new BehaviorSubject<boolean>(false);

  public constructor(
    private readonly userService: UserService,
    private readonly appConfig: AppConfigService,
    private readonly router: Router,
  ) {
    this.isAuthorized$ = this.userService.isAuthorized$;
    this.marketingSiteUrl = this.appConfig.marketingSiteUrl;
    this.companySiteUrl = `${this.appConfig.marketingSiteUrl}/about`;
    this.featuresSiteUrl = `${this.appConfig.marketingSiteUrl}/features`;
    this.pricingSiteUrl = `${this.appConfig.marketingSiteUrl}/pricing`;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    const onRouterNavigationSideEffect$ = this.router.events.pipe(
      tap(() => this.isMenuOpened$.next(false)),
    );

    onRouterNavigationSideEffect$.pipe(
      takeUntilDestroy(this),
    ).subscribe();
  }

  /** Handles the logout click. */
  public onLogoutClick(): void {
    this.userService.logout().pipe(
      takeUntilDestroy(this),
    )
      .subscribe();
  }
}
