import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TaskBaseTemplate } from '../task-base-template';

/**
 * Represent single task.
 */
@Component({
  selector: 'zw-task',
  templateUrl: './task.component.html',
  styleUrls: ['../task-base-template.css', './task.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskComponent extends TaskBaseTemplate {
  /** Whether show task notes. */
  @Input()
  public withNote = false;
}
