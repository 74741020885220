import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@zc/common/core/models/address';

/** Address formatting pipe. */
@Pipe({
  name: 'zcAddress',
})
export class AddressPipe implements PipeTransform {

  /** @inheritdoc */
  public transform(value: Address): string {
    return [value.addressLine1, this.unit(value), this.cityStateZipCode(value)]
      .filter(addressPart => addressPart && addressPart !== '')
      .join(', ');
  }

  /**
   * If the address2 is digits, we will add “#” before the digits.
   * @param address Address.
   */
  private unit(address: Address): string | undefined {
    if (address === null) {
      return void 0;
    }

    if (!address.addressLine2 || address.addressLine2.length === 0) {
      return void 0;
    }
    const isDigits = Number.isInteger(Number(address.addressLine2));

    return isDigits ? `#${address.addressLine2}` : address.addressLine2;
  }

  /**
   * Get city, state and zipcode.
   * @param address Address.
   */
  private cityStateZipCode(address: Address): string {
    const cityState: (keyof Address)[] = ['city', 'state'];
    const cityStatePropertiesOrder = cityState.map(key => address?.[key])
      .map(addressPart => typeof addressPart === 'string' ? addressPart : addressPart?.abbreviation)
      .filter(addressPart => addressPart !== '')
      .join(', ');

    return `${cityStatePropertiesOrder} ${address?.zipCode}`;
  }
}
