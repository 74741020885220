import { Directive, ElementRef } from '@angular/core';

/**
 * Directive that set default attributes for the first option of `select` element
 * in order to make it a some kind of placeholder.
 */
@Directive({
  selector: 'option[zcPlaceholderOption]',
})
export class SelectPlaceholderDirective {

  public constructor(elementRef: ElementRef) {
    elementRef.nativeElement.setAttribute('selected', true);
    elementRef.nativeElement.setAttribute('disabled', true);
    elementRef.nativeElement.setAttribute('hidden', true);
  }
}
