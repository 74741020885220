<ng-container *ngIf="withLabel; else withoutLabel">
  <input
    [disabled]="disabled"
    class="checkbox"
    [(ngModel)]="controlValue"
    type="checkbox"
    *ngIf="label; else skeleton"
  />
  <span
    zcEllipsis
    class="label__text"
    [zcSkeleton]="label"
    [zcSkeletonMinWidth]="5"
    [zcSkeletonMaxWidth]="15"
  >
    {{ label }}
  </span>
</ng-container>

<ng-template #withoutLabel>
  <input
    [disabled]="disabled"
    class="checkbox"
    [(ngModel)]="controlValue"
    type="checkbox"
  />
</ng-template>

<ng-template #skeleton>
  <div class="checkbox checkbox_skeleton" zcSkeleton></div>
</ng-template>
