import { ChangeDetectionStrategy, Component } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

/**
 * Masked control for percents.
 */
@Component({
  selector: 'zc-masked-percent-control',
  templateUrl: './masked-percent-control.component.html',
  styleUrls: ['./masked-percent-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(MaskedPercentControlComponent)],
})
export class MaskedPercentControlComponent extends SimpleValueAccessor<number> { }
