import { Directive, Input, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileFormat } from '@zc/common/core/enums/file-format';
import { getImageSource } from '@zc/common/core/utils/image-src';

/** Base implementation of file preview component. */
@Directive()
export class BaseFilePreviewDirective implements OnDestroy {

  /** File to preview. */
  @Input()
  public set file(f: File | string | null) {
    if (f != null) {
      this.safeSource = this.getFileSource(f);
      this.safeSourceUrl = this.unwrapFileSource(this.safeSource);

      if (typeof f === 'string') {
        this.isVideo = FileFormat.isVideo(f);
      } else {
        this.isVideo = FileFormat.isVideo(f.name);
      }

      this.fileFormat = FileFormat.fromFile(typeof f === 'string' ? f : f.name);
    }
  }

  /** Teardown function of a resource url.  */
  private currentSourceTeardownFn: (() => void) | null = null;

  /** Safe source. */
  public safeSource: SafeResourceUrl | null = null;

  /** Url to file source. */
  public safeSourceUrl: string | null = null;

  /** Whether file is represent video or not. */
  public isVideo = false;

  /** Format of current file. */
  public fileFormat: FileFormat = FileFormat.Unknown;

  public constructor(
    private readonly sanitizer: DomSanitizer,
  ) {}

  /**
   * Get source of current file.
   * @param file File.
   */
  private getFileSource(file: File | string): SafeResourceUrl {
    const imageSource = getImageSource(file);
    this.currentSourceTeardownFn?.();
    this.currentSourceTeardownFn = imageSource.teardown;
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageSource.unsanitizedUri);
  }

  /**
   * Unwraps a safe resource url from Angular wrapper in order to use it in a secure context.
   * @param safeResourceUrl Url to unwrap.
   */
  public unwrapFileSource(safeResourceUrl: SafeResourceUrl): string | null {
    return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, safeResourceUrl);
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.currentSourceTeardownFn?.();
  }
}
