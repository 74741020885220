<input
  [placeholder]="placeholder"
  [(ngModel)]="controlValue"
  [disabled]="disabled"
  [allowNegativeNumbers]="allowNegative"
  class="masked-control"
  type="text"
  thousandSeparator=","
  mask="separator.2"
>
