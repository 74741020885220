import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppConfigService } from '@zc/common/core/services/app-config.service';

/** Landing without header component. */
@Component({
  selector: 'zw-landing-without-header',
  templateUrl: './landing-without-header.component.html',
  styleUrls: ['./landing-without-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingWithoutHeaderComponent {

  /** Homepage marketing site url. */
  public readonly marketingSiteUrl: string;

  public constructor(
    private readonly appConfig: AppConfigService,
  ) {
    this.marketingSiteUrl = this.appConfig.marketingSiteUrl;
  }

}
