import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDatepicker } from '@matheo/datepicker';
import { DateRange } from '@zc/common/core/models/date-range';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';
import { endOfDay, endOfMonth, endOfQuarter, endOfYear } from 'date-fns';

import { DatepickerType } from '../datepicker/datepicker.component';

const TO_END_DATE_MAPPERS: Readonly<Record<DatepickerType, (d: Date) => Date>> = {
  [DatepickerType.Time]: date => date,
  [DatepickerType.Datetime]: date => date,
  [DatepickerType.Date]: date => endOfDay(date),
  [DatepickerType.Month]: date => endOfMonth(date),
  [DatepickerType.Quarter]: date => endOfQuarter(date),
  [DatepickerType.Year]: date => endOfYear(date),
};

/** Date range picker. */
@Component({
  selector: 'zc-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(DateRangePickerComponent)],
})
export class DateRangePickerComponent extends SimpleValueAccessor<DateRange> {
  /** Datepicker type. */
  @Input()
  public type: DatepickerType = DatepickerType.Date;

  /** Datepicker element. */
  @ViewChild('datepicker')
  public datepicker?: ElementRef<MatDatepicker<Date>>;

  /** Whether the dates after current date should be disabled or not. */
  @Input()
  public untilNow = false;

  /**
   * Filter for second datepicker in a range.
   * @param date Date.
   */
  public readonly secondDateFilter = (date: Date | null): boolean => {
    if (date == null || this.controlValue == null) {
      return false;
    }

    const isAfterStartDate = date.valueOf() > this.controlValue.start.valueOf();
    return isAfterStartDate;
  };

  /**
   * Handles selection of the first date.
   * @param date Date.
   */
  public onFirstDatePick(date: Date): void {
    const end = this.controlValue?.end;
    this.controlValue = {
      end: (end != null && end.valueOf() > date.valueOf()) ? end : date,
      start: date,
    };
  }

  /**
   * Handles the selection of second date in a range.
   * @param date Date.
   */
  public onLastDatePick(date: Date): void {
    this.controlValue = {
      start: this.controlValue?.start ?? new Date(),
      end: this.mapToEndOfDate(date),
    };
  }

  /**
   * Depending on a type of date selected, we need to build the range from the start of first period to the end of second period.
   * For example, if a year 2021 to year 2023 selected, we need to select from Jan 1, 2021 to Dec 31, 2023.
   * @param date Date to map.
   */
  private mapToEndOfDate(date: Date): Date {
    return TO_END_DATE_MAPPERS[this.type](date);
  }
}
