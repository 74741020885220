<qrcode
  [qrdata]="qrData"
  [elementType]="elementType"
  [width]="width"
  #qrcodeImage
></qrcode>
<div class="qrcode__controls">
  <button
    type="button"
    class="qrcode__button primary"
    (click)="downloadQR(qrcodeImage)"
  >
    Download QR Code Image
  </button>
</div>
