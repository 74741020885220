import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

/**
 * Component of masked number control.
 */
@Component({
  selector: 'zc-masked-number-control',
  templateUrl: './masked-number-control.component.html',
  styleUrls: ['./masked-number-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(MaskedNumberControlComponent)],
})
export class MaskedNumberControlComponent extends SimpleValueAccessor<number> {

  /** Placeholder for the control. */
  @Input()
  public placeholder = '';

  /** Whether control can have negative values or no. */
  @Input()
  public allowNegative = false;
}
