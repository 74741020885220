import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { User } from '@zc/common/core/models/user';
import { UserService } from '@zc/common/core/services/user.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

/** Resolver that waits for the user to be loaded. */
@Injectable({ providedIn: 'root' })
export class AppRoutingResolver implements Resolve<User | null> {

  public constructor(
    private readonly userService: UserService,
  ) {}

  /** @inheritdoc */
  public resolve(): Observable<User | null> {
    return this.userService.currentUser$.pipe(first());
  }
}
