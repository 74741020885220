import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Address } from '@zc/common/core/models/address';

/** Inline address component. */
@Component({
  selector: 'zc-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent {

  /** Address object. */
  @Input()
  public address: Address | null = null;

  /** Get formated address. */
  public getFormattedAddress = Address.getFormattedAddress;
}
