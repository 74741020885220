<div class="image-uploader">
  <div class="image-uploader__content">
    <ng-content></ng-content>
  </div>
  <input
    class="image-uploader__input visually-hidden"
    (change)="_onChange()"
    [disabled]="true"
    #fileInput
    accept="image/*, video/*"
    type="file"
  />
</div>
