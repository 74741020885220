import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonSharedModule } from '@zc/common/shared/common-shared.module';

import { WebSharedModule } from '../../shared/web-shared.module';

import { LandingRoutingModule } from './landing-routing.module';
import { LandingComponent } from './landing.component';
import { ProductComponent } from './product/product.component';
import { LandingWithoutHeaderComponent } from './auth/landing-without-header/landing-without-header.component';

/** Landing module. */
@NgModule({
  declarations: [
    LandingComponent,
    ProductComponent,
    LandingWithoutHeaderComponent,
  ],
  imports: [
    CommonModule,
    CommonSharedModule,
    LandingRoutingModule,
    WebSharedModule,
  ],
})
export class LandingModule { }
