import { Directive, Input } from '@angular/core';
import { ProjectTaskStatusType } from '@zc/common/core/models/project-task-status';
import { ProjectTask } from '@zc/common/core/models/tasks/project-task';

/** Base implementation of task template. */
@Directive()
export abstract class TaskBaseTemplate {
  /** Task. */
  @Input()
  public task: ProjectTask | null = null;

  /** Returns human-readable representation of task status. */
  public readonly toReadableTaskStatus = ProjectTaskStatusType.toReadable;

  /** Preposition for due date. */
  public get datePreposition(): string {
    return this.task?.isExpired ? 'PAST DUE' : 'DUE';
  }

  /** Gets classes for badge of current task status. */
  public getTaskStatusBadgeClasses(): { [key: string]: boolean; } {
    if (this.task) {
      const isNotCompleted = this.task.status === ProjectTaskStatusType.NotStarted || this.task.status === ProjectTaskStatusType.InProgress;

      if (isNotCompleted) {
        return {
          'badge_not-started': this.task.status === ProjectTaskStatusType.NotStarted,
          'badge_in-progress': this.task.status === ProjectTaskStatusType.InProgress,
        };
      }
      return {
        badge_completed: true,
      };
    }

    return { 'badge_not-started': true };
  }
}
