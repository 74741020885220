import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { UserService } from '@zc/common/core/services/user.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** Landing guard. Redirects user to member portal whenever they are logged in. */
@Injectable({ providedIn: 'root' })
export class LandingGuard implements CanLoad, CanActivate, CanActivateChild {
  public constructor(
    private readonly userService: UserService,
    private readonly router: Router,
  ) { }

  /** @inheritdoc */
  public canActivateChild(): Observable<boolean | UrlTree> {
    return this.redirectToMemberPortalIfAuthorized();
  }

  /** @inheritdoc */
  public canActivate(): Observable<boolean | UrlTree> {
    return this.redirectToMemberPortalIfAuthorized();
  }

  /** @inheritdoc */
  public canLoad(): Observable<boolean | UrlTree> {
    return this.redirectToMemberPortalIfAuthorized();
  }

  private redirectToMemberPortalIfAuthorized(): Observable<boolean | UrlTree> {
    return this.userService.isAuthorized$.pipe(
      map(isAuthorized => isAuthorized ? this.router.createUrlTree(['/member-portal']) : true),
    );
  }
}
