import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

type ComparatorFn<T> = (a: T | null | undefined, b: T | null | undefined) => boolean;

/**
 * Custom radio group control.
 * @example
 * ```html
 * <fieldset>
 *  <legend>Checkbox group</legend>
 *  <zc-radio-group
 *    [options]="[1, 2, 3]"
 *    [toReadable]="optionToReadable"
 *    [comparator]="optionComparator"
 *    [formControl]="control"
 *  />
 * </fieldset>
 * ```
 */
@Component({
  selector: 'zc-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(RadioGroupComponent)],
})
export class RadioGroupComponent<T> extends SimpleValueAccessor<T> {
  /**
   * Array of options.
   */
  @Input()
  public options: readonly T[] | null = [];

  /**
   * Form control name.
   */
  @Input()
  public formControlName!: string;

  /**
   * Radio group name.
   */
  @Input()
  public name!: string;

  /**
   * Explicitly set role radiogroup for the component.
   */
  @HostBinding('attr.role')
  public readonly role = 'radiogroup';

  /**
   * Comparator function for object. To be able to distinct unique values.
   * @param a First.
   * @param b Second.
   */
  @Input()
  public comparator: ComparatorFn<T> = (a, b) => a === b;

  /**
   * Makes object human-readable.
   * @param item Item to make readable.
   */
  @Input()
  public toReadable: (item: T) => string = item => String(item);

  /**
   * Handle change of input value.
   * @param option Option to check.
   */
  public onCheckChange(option: T): void {
    this.controlValue = this.options?.find(opt => this.comparator(option, opt)) ?? null;
  }
}
