<div class="date-range">
  <zc-datepicker
    [type]="type"
    [ngModel]="controlValue?.start"
    (ngModelChange)="onFirstDatePick($event)"
    [untilNow]="untilNow"
    class="date-range__picker"
  ></zc-datepicker>
  <zc-datepicker
    [type]="type"
    [ngModel]="controlValue?.end"
    (ngModelChange)="onLastDatePick($event)"
    [untilNow]="untilNow"
    [dateFilter]="secondDateFilter"
    class="date-range__picker"
  ></zc-datepicker>
</div>
