import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FileFormat } from '@zc/common/core/enums/file-format';
import { createTrackByFunction } from '@zc/common/core/utils/trackby';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';
import { ReplaySubject } from 'rxjs';

/**
 * Get files from a list.
 * @param list Files list.
 */
function getFilesFromFileList(list: FileList): File[] {
  return Array.from(list).filter(file => file != null);
}

/**
 * Drag and drop component for documents.
 */
@Component({
  selector: 'zc-document-drop',
  templateUrl: './document-drop.component.html',
  styleUrls: ['./document-drop.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(DocumentDropComponent)],
})
export class DocumentDropComponent extends SimpleValueAccessor<readonly File[]> {

  /** Uploaded files. */
  public readonly uploadedFiles$ = new ReplaySubject<readonly File[] | null>(1);

  /** File format. */
  public readonly FileFormat = FileFormat;

  /** Track by function for files. */
  public readonly trackFile = createTrackByFunction<File>('name');

  /**
   * Handle file uploading.
   * @param event Upload event.
   */
  public onFileUpload(event: Event): void {
    const documents = (event.target as HTMLInputElement).files;
    if (documents != null) {
      const files = getFilesFromFileList(documents);
      this.emitChange(files);
      this.uploadedFiles$.next(files);
    } else {
      this.emitChange(null);
      this.uploadedFiles$.next(null);
    }
  }
}
